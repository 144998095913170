import {Button, Popconfirm, Tooltip} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useMutation} from "../../api";

export function DeleteFile({id, fetch}) {

    const usePost = useMutation({url: `/upload/${id}`, method: "DELETE"})

    const onDelete = async () => {
        usePost.mutate({}, {
            onSuccess: () => {
                fetch()
            },
        })
    };


    return (
        <Popconfirm
            title="هل أنت متأكد من حذف هذا الملف?"
            onConfirm={onDelete}
            okText="آحذف"
            cancelText="الغاء"
        >
            <Button type="primary" danger icon={<CloseCircleOutlined/>}/>
        </Popconfirm>
    );
}

