import React, {useMemo} from 'react';
import {useFetch} from "../../api";
import {AddInvoice} from "./add-invoice";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import moment from "moment";
import {EditInvoice} from "./edit-invoice";
import PageHeading from "../../components/page-heading";
import {RefreshButton} from "../../components/refresh-button";
import {formatNumber} from "../../utils/utils";
import {useAuth} from "../../security";

export function Invoices(props) {
    const {isAdmin} = useAuth()

    const {data = {}, isLoading, fetch} = useFetch({url: '/invoice'})

    const columns = useMemo(() => [
        isAdmin && {
            id: 'actions',
            accessorKey: 'actions',
            header: 'آوامر',
            cell: info => <div>
                <EditInvoice id={info.row.original.id}/>
            </div>,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: 'الرمز',
            // cell: info => <Link to={`/invoice/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: 'الآسم',
            // cell: info => <Link to={`/invoice/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            accessorFn: row => moment(row.date).format('DD-MM-YYYY'),
            id: 'date',
            header: 'التاريخ'
        },
        {
            accessorFn: row => row.project.name,
            id: 'project',
            header: 'المشروع'
        },
        {
            accessorFn: row => row.category,
            id: 'category',
            header: 'الصنف'
        },
        {
            accessorFn: row => row.type === 'Debit' ? 'المصروف' : 'الوارد',
            id: 'type',
            header: 'النوع'

        },
        {
            accessorFn: row => row?.total?.iqd || 0,
            id: 'total.iqd',
            header: 'دينار',
            cell: info => formatNumber(info.getValue(), null, 0),
        },
        {
            accessorFn: row => row.total?.usd || 0,
            id: 'total.usd',
            header: 'دولار',
            cell: info => formatNumber(info.getValue(), null, 0),

        },
        {
            accessorFn: row => row.total?.exchangeRate || 0,
            id: 'total.exchangeRate',
            header: 'سعر التحويل',
            cell: info => formatNumber(info.getValue(), null, 0),

        },
    ], [data, isLoading]);


    return (
        <Shell currentPage="invoice" isLoading={isLoading}>
            <PageHeading
                title={'الفواتير'}
                subtitle={'كشف الفواتير في الشركة.'}
            />
            <OwlTable
                data={data?.results || []}
                columns={columns}

                buttons={[isAdmin && <AddInvoice/>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
            />
        </Shell>
    );
}
