import {useFetch} from "../../api";
import {sum, sumBy} from "lodash";
import {formatNumber} from "../../utils/utils";
import {useMemo} from "react";
import {Spin} from "antd";

export function Widgets(props) {

    const {data: {results: project = []} = {}} = useFetch({url: '/project'})
    const {data: {results: loans = []} = {}, isLoading: isLoadingLoans} = useFetch({url: '/loan'})
    const {data = {}, isLoading: isLoadingBalance} = useFetch({url: '/balance/all'})


    const getStats = (data) => {
        const totalUSD = sumBy(data, 'total.usd')
        const totalIQD = sumBy(data, 'total.iqd')
        const totalUSDConverted = sum(data.map(i => Number(i.total.usd) * Number(i.total.exchangeRate)))
        const total = totalIQD + totalUSDConverted

        return {totalUSD, totalIQD, totalUSDConverted, total}
    }

    const loanList = useMemo(() => loans.filter(i => i.type === "Loan"), [loans])
    const paybackList = useMemo(() => loans.filter(i => i.type === "Payback"), [loans])
    const totalLoan = useMemo(() => getStats(loanList), [loans])
    const totalCredit = useMemo(() => getStats(paybackList), [loans])

    const balanceUSD = useMemo(() => totalCredit.totalUSD - totalLoan.totalUSD, [loans])
    const balanceIQD = useMemo(() => totalCredit.totalIQD - totalLoan.totalIQD, [loans])

    return (
        <div className="pt-12 sm:pt-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        منظومة الحسابات لشركة <span className="text-indigo-600">فن الطراز</span>
                    </h2>
                    <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                        منظومة للآدارة الاعمال و المصاريف
                    </p>
                </div>
            </div>
            <div className="mt-10 pb-12 sm:pb-16">
                <div className="relative">
                    <div className="absolute inset-0 h-1/2"/>
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-4xl mx-auto">
                            <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                <div
                                    className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">المشاريع</dt>
                                    <dd className="order-1 text-4xl font-extrabold text-indigo-600">{project.length}</dd>
                                </div>
                                <div
                                    className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">الصافي
                                        بالعراقي
                                    </dt>
                                    <dd className="order-1 text-4xl font-extrabold text-indigo-600">{isLoadingBalance ?
                                        <Spin/> : formatNumber(sumBy(data.lines, i => i?.total?.iqd), null, 0)}</dd>
                                </div>
                                <div
                                    className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">الصافي
                                        بالدولار
                                    </dt>
                                    <dd className="order-1 text-4xl font-extrabold text-indigo-600">{isLoadingBalance ?
                                        <Spin/> : formatNumber(sumBy(data.lines, i => i?.total?.usd), null, 0)}</dd>
                                </div>
                                <div
                                    className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">الديون
                                        بالعراقي
                                    </dt>
                                    <dd className="order-1 text-4xl font-extrabold text-indigo-600">{isLoadingLoans ?
                                        <Spin/> : formatNumber(Math.abs(balanceIQD), null, 0)}</dd>
                                </div>
                                <div
                                    className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">الديون
                                        بالدولار
                                    </dt>
                                    <dd className="order-1 text-4xl font-extrabold text-indigo-600">{isLoadingLoans ?
                                        <Spin/> : formatNumber(Math.abs(balanceUSD), 'USD', 0)}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
