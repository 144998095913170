import {Fragment, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {
    BellIcon,
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    MenuAlt2Icon,
    UsersIcon,
    XIcon,
} from '@heroicons/react/outline'
import {SearchIcon, UserGroupIcon} from '@heroicons/react/solid'
import logo from "../../logo.png";
import Footer from "./footer";
import {PageSpinner} from "../page-spinner";
import {useNavigate} from "react-router-dom";
import {Spin} from "antd";

const navigation = [
    {id: 'main', name: 'الواجهة الرئسية', href: '/', icon: HomeIcon},
    {id: 'project', name: 'المشاريع', href: '/project', icon: UsersIcon},
    {id: 'invoice', name: 'الفواتير', href: '/invoice', icon: UsersIcon},
    {id: 'wage', name: 'الاسبوعيات', href: '/wage', icon: FolderIcon},
    {id: 'loan', name: 'الديون', href: '/loan', icon: FolderIcon},
    {id: 'worker', name: 'العمال', href: '/worker', icon: CalendarIcon},
    {id: 'balance', name: 'القاصة', href: '/balance', icon: InboxIcon},
    {id: 'exchange', name: 'تصريف عملات', href: '/exchange', icon: ChartBarIcon},
]
const userNavigation = [
    // {name: 'Your Profile', href: '#'},
    // {name: 'Settings', href: '#'},
    {name: 'Sign out', href: '/logout'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function Shell({children, currentPage = 'main', isLoading = false}) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate()

    return (
        <div style={{direction: 'ltr'}} className="h-full">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <div
                                        className="text-3xl text-gray-200 flex space-x-2 w-full h-full justify-center items-center">
                                        <UserGroupIcon className="h-8 w-8"/>
                                        <div className="mt-3">Axio<strong>matic</strong></div>
                                    </div>
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2 space-y-1">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.id === currentPage
                                                        ? 'bg-gray-900 text-white'
                                                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.id === currentPage ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                                        'mr-4 flex-shrink-0 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/*Static sidebar for desktop */}
            <div className="hidden lg:flex lg:w-56 lg:flex-col lg:fixed lg:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
                    <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">

                        <div
                            className="text-3xl text-gray-200 flex space-x-2 w-full h-full justify-center items-center">
                            <UserGroupIcon className="h-8 w-8"/>
                            <div className="mt-3">Axio<strong>matic</strong></div>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col overflow-y-auto">
                        <nav className="flex-1 px-2 py-4 space-y-1">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        item.id === currentPage ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.id === currentPage ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            <div className="lg:pl-56 flex flex-col w-full h-full">
                <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
                    <button
                        type="button"
                        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                    <div className="flex-1 px-4 flex justify-between">
                        <div className="flex-1 flex">
                            {/*<form className="w-full flex md:ml-0" action="#" method="GET">*/}
                            {/*    <label htmlFor="search-field" className="sr-only">*/}
                            {/*        Search*/}
                            {/*    </label>*/}
                            {/*    <div className="relative w-full text-gray-400 focus-within:text-gray-600">*/}
                            {/*        <div*/}
                            {/*            className="absolute inset-y-0 left-0 flex items-center pointer-events-none">*/}
                            {/*            <SearchIcon className="h-5 w-5" aria-hidden="true"/>*/}
                            {/*        </div>*/}
                            {/*        <input*/}
                            {/*            id="search-field"*/}
                            {/*            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"*/}
                            {/*            placeholder="Search"*/}
                            {/*            type="search"*/}
                            {/*            name="search"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</form>*/}
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                            {/*>*/}
                            {/*    <span className="sr-only">View notifications</span>*/}
                            {/*    <BellIcon className="h-6 w-6" aria-hidden="true"/>*/}
                            {/*</button>*/}

                            {/*<img src={logo} alt="Logo" className="h-8 cursor-pointer focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 " onClick={() => navigate('/')}/>*/}
                            {/* Profile dropdown*/}
                            <Menu as="div" className="ml-3 relative">
                                <div>
                                    <Menu.Button
                                        className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Open user menu</span>
                                        <img src={logo} alt="Logo" className="h-8"/>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({active}) => (
                                                    <a
                                                        href={item.href}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <main className="flex-1 flex flex-col" style={{direction: 'rtl'}}>
                    <div className="h-full mx-auto w-full p-8">
                        <Spin tip="Loading..." spinning={isLoading} size="large">
                            {children}
                        </Spin>
                    </div>
                    <Footer/>
                </main>
            </div>

        </div>
    )
}
