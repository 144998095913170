import {Alert, Button, DatePicker, Form, Input, InputNumber, Table} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {isEmpty, isNumber, omit, pick, sum, sumBy} from "lodash";

import {useFetch, useMutation} from "../../api";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {PageSpinner} from "../../components/page-spinner";
import {TableHead} from "../../components/owl-table/table-head";
import {TableBody} from "../../components/owl-table/table-body";
import TablePagination from "../../components/owl-table/table-pagination";
import {rules} from "../../utils/rules";
import {formatNumber} from "../../utils/utils";
import moment from "moment";
import {useQueryClient} from "react-query";
import {FileAddOutlined} from "@ant-design/icons";

export function WageAdd(props) {

    const {data: proj = {}, isLoading: isLoadingProject} = useFetch({url: `/project`})
    console.log({proj})
    const {data: wrk = {}, isLoading: isLoadingWorker} = useFetch({url: `/worker`})

    const isLoading = isLoadingWorker || isLoadingProject

    const [projects, setProjects] = useState([]);
    const [worker, setWorker] = useState([]);

    useEffect(() => {
        if (!isEmpty(proj.results) && !isEmpty(wrk.results)) {
            setProjects(proj.results.filter(i => i.status === 'Active'))
            setWorker(wrk.results.filter(i => i.status === 'Active'))
        }

    }, [proj, wrk]);


    const [newData, setNewData] = useState({});
    const [wage, setWage] = useState([]);

    useEffect(() => {
        const _newData = worker?.reduce((acc, i) => {
            return {
                ...acc,
                [i.id]: {
                    ...i,
                    cost: i?.salary,
                    totalDays: 0,
                    total: 0,
                    project: projects?.reduce((acc, i) => ({
                        ...acc,
                        [i.id]: null,
                    }), {})
                }
            }
        }, {})
        setNewData(_newData)
        form.setFieldsValue(_newData)
        setWage([])
    }, [projects, worker]);


    // const columns = [
    //     {
    //         accessorKey: 'name',
    //         id: 'name',
    //         header: 'العامل',
    //         cell: info => ,
    //     },
    //     {
    //         accessorKey: 'cost',
    //         id: 'cost',
    //         header: 'اليومية',
    //     },
    //     ...projects?.map(i => ({
    //         accessorKey: ['project', i.id],
    //         id: ['project', i.id],
    //         header: i.name,
    //     })),
    //     {
    //         accessorKey: 'totalDays',
    //         id: 'totalDays',
    //         header: 'عدد الآيام',
    //         cell: info => sum(Object.values(info.row.original.project).map(Number))
    //     },
    //     {
    //         accessorKey: 'total',
    //         id: 'total',
    //         header: 'المجموع',
    //         cell: info => {
    //             const days = sum(Object.values(info.row.original.project).map(Number))
    //             const cost = Number(info.row.original.cost)
    //             return days * cost
    //         }
    //
    //     },
    // ]

    const [form] = Form.useForm();


    const InputNum = ({name, rules: _rules, index = 0}) => <Form.Item
        name={name}
        rules={_rules}
        noStyle
    >
        <InputNumber
            min={0}
            style={
                {
                    background: index % 2 === 0 ? undefined : 'rgb(243 244 246)',
                    width: '100%',
                    outlineWidth: 0,
                    border: 'none'
                }}/>
    </Form.Item>


    const InputStatic = ({value}) => <Form.Item noStyle>
        <Input style={{width: '100%', outlineWidth: 0, border: 'none', background: 'rgb(243 244 246)'}}
               value={value}/>
    </Form.Item
    >


    const usePost = useMutation({url: '/wage'})
    const queryClient = useQueryClient()
    const navigate = useNavigate()


    const handleOk = async () => {

        const _sendData = {date: wage[0].date, lines: wage.map(i => omit(i, 'date'))}

        usePost.mutate(_sendData, {
            onSuccess: (newData) => {
                queryClient.setQueryData('/wage', (oldData = []) =>
                    ({...oldData, results: [...oldData.results, newData]}))

                navigate(`/wage/${newData.id}`);
            },
        })
    };


    /// <Link to={`/wage/${info.row.original.id}`}>{info.getValue()}</Link>

    console.log(wage)

    return <Shell currentPage="wage" loading={isLoading}>
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">الآسبوعيات</h1>
            </div>
        </div>

        <Form
            form={form}
            name="new-wage"
            initialValues={{date: {start: moment()}}}
            onBlur={async (e) => {
                const values = await form.validateFields()

                console.log(values)
                const w = Object.entries(omit(values, ['date'])).map(([key, value]) => {
                    const _worker = worker.find(i => i.id === key)
                    return {
                        cost: value.cost,
                        days: sum(Object.values(value.project)),
                        total: sum(Object.values(value.project)) * value.cost,
                        project: Object.entries(value.project).reduce((acc, [k, v]) => ([...acc, {
                            ...pick(projects.find(j => j.id === k), ['id', 'name']),
                            days: v,
                        }]), []),
                        worker: pick(_worker, ['id', 'name']),
                        date: {
                            start: moment(values?.date?.start),
                            end: moment(values?.date?.start).add(7, 'day'),
                        }
                    }

                })
                setWage(w)
            }}
        >

            <Form.Item
                name={["date", 'start']}
                rules={rules.date}
                label="اختار بداية الاسبوع"
            >
                <DatePicker/>
            </Form.Item>


            <div className="mt-8 flex flex-col w-full">
                <div className="p-1 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                            <table className="min-w-full divide-y divide-gray-300  table-auto">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                                        العامل
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        اليومية
                                    </th>

                                    {projects.map(i => <th scope="col"
                                                           className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {i.name}
                                    </th>)}

                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        عدد الآيام
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-56">
                                        المجموع
                                    </th>

                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {Object.values(newData)?.map((j, index) => (
                                    <tr>
                                        <td className="whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100">
                                            <InputStatic value={j.name}/>
                                        </td>

                                        <td className="whitespace-nowrap text-sm text-gray-500">
                                            <InputNum name={[j.id, "cost"]}/>
                                        </td>

                                        {projects?.map(i =>
                                            <td className={`whitespace-nowrap text-sm text-gray-500 border-r-2 flex-1`}>
                                                <InputNum name={[j.id, 'project', i.id]} index={index}/>
                                            </td>
                                        )}

                                        <td className="whitespace-nowrap text-sm text-gray-500 bg-gray-100">
                                            <InputStatic
                                                value={formatNumber(wage?.find(q => q.worker.id === j.id)?.days || 0, null, 1)}
                                                name="totalDays"/>
                                        </td>

                                        <td className="whitespace-nowrap text-sm text-gray-500 bg-gray-100">
                                            <InputStatic
                                                value={formatNumber(wage?.find(q => q.worker.id === j.id)?.total || 0, null, 0)}
                                                name="total"/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                                {/*<tfoot>*/}
                                {/*<TableFooter table={table}/>*/}
                                {/*</tfoot>*/}
                            </table>
                        </div>
                        <Button type="primary" onClick={handleOk} className="mt-4" icon={<FileAddOutlined/>}>
                            إضافة
                        </Button>
                    </div>
                </div>
            </div>
        </Form>
    </Shell>
}
