import moment from "moment";
import accounting from 'accounting'
import getSymbolFromCurrency from 'currency-symbol-map'

export function strip(text = '') {
    return text.replace(/<[^>]*>?/gm, '')
}

export function formatNumber(x = 0, currency, decimal = 2) {
    const w = accounting.formatMoney(
        x,
        getSymbolFromCurrency(currency) || '',
        currency === 'IQD' ? 0 : decimal
    )

    if (x === 0 || w === 0) return x
    return w
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
