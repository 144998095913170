import React, {useMemo} from 'react';
import moment from "moment";
import {sum, sumBy} from "lodash";

import {useFetch} from "../../api";
import AddLoan from "./add-loan";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {EditLoan} from "./edit-loan";
import {formatNumber} from "../../utils/utils";
import {RefreshButton} from "../../components/refresh-button";
import {useAuth} from "../../security";

export function Loans() {

    const {isAdmin} = useAuth()

    const {data = {}, isLoading, fetch} = useFetch({url: '/loan'})

    const columns = useMemo(() => [
        isAdmin && {
            id: 'actions',
            accessorKey: 'actions',
            header: 'آوامر',
            cell: info => <div>
                <EditLoan id={info.row.original.id}/>
            </div>,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: 'الرمز',
        },
        {
            accessorFn: row => moment(row.date).format('DD-MM-YYYY'),
            id: 'date',
            header: 'التاريخ'
        },
        {
            accessorKey: 'from',
            id: 'from',
            header: 'المدين',
        },
        {
            accessorFn: row => row?.type === 'Loan' ? "دين" : "آرجاع دين",
            id: 'type',
            header: 'النوع',
        },
        {
            accessorFn: row => row?.total?.iqd || 0,
            cell: info => formatNumber(info.getValue()),

            id: 'total.iqd',
            header: 'دينار'
        },
        {
            accessorFn: row => row.total?.usd || 0,
            cell: info => formatNumber(info.getValue(), 'USD'),

            id: 'total.usd',
            header: 'دولار'
        },
        {
            accessorFn: row => row.total?.exchangeRate || 0,
            cell: info => formatNumber(info.getValue()),
            id: 'total.exchangeRate',
            header: 'سعر التحويل'
        },
        {
            accessorKey: 'description',
            id: 'description',
            header: 'ملاحضات',
        },
    ], [data, isLoading]);


    const getStats = (data) => {
        const totalUSD = sumBy(data, 'total.usd')
        const totalIQD = sumBy(data, 'total.iqd')
        const totalUSDConverted = sum(data.map(i => Number(i.total.usd) * Number(i.total.exchangeRate)))
        const total = totalIQD + totalUSDConverted

        return {totalUSD, totalIQD, totalUSDConverted, total}
    }

    const list = data?.results || []
    const loanList = useMemo(() => list.filter(i => i.type === "Loan"), [list])
    const paybackList = useMemo(() => list.filter(i => i.type === "Payback"), [list])
    const totalLoan = useMemo(() => getStats(loanList), [list])
    const totalCredit = useMemo(() => getStats(paybackList), [list])

    const balance = useMemo(() => totalCredit.total - totalLoan.total, [list])

    return (
        <Shell currentPage="loan" isLoading={isLoading}>

            <div className="flex mt-5">
                <div>
                    <h1 className="text-2xl font-semibold text-gray-900">الديون</h1>
                    <p className="mt-2 text-xl text-gray-700">
                        كشف ديون الموظفين
                    </p>
                </div>
                <div className="sm:w-1/3 w-full mr-auto pr-3">
                    <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:px-3 sm:pt-6 sm:pr-6">
                        <dt className="text-sm font-semibold text-gray-500 truncate">الصافي الاجمالي بالدينار</dt>
                        <dd className={`mt-1 text-2xl font-semibold ${balance > 0 ? 'text-green-600' : 'text-red-600'}`}>{formatNumber(balance, null, 0)}</dd>
                    </div>
                </div>
            </div>


            <OwlTable
                data={data?.results || []}
                columns={columns}

                // title={'الديون'}
                // subtitle={'كشف مشا سهر يسيس سي ا.'}
                buttons={[isAdmin && <AddLoan/>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
            />
        </Shell>
    );
}
