import {Button, Popconfirm, Table, Tabs} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMemo} from "react";
import {pick, sum, sumBy} from "lodash";

import {useFetch, useMutation} from "../../api";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {PageSpinner} from "../../components/page-spinner";
import {formatNumber} from "../../utils/utils";
import {useQueryClient} from "react-query";
import moment from "moment";
import {useAuth} from "../../security";
import {DollarOutlined, FilePdfOutlined} from "@ant-design/icons";
import {Upload} from "../../components/upload";

export function Wage(props) {
    let {id} = useParams();
    const {isAdmin} = useAuth()


    const {data: wage = [], isLoading: isLoadingWage} = useFetch({url: `/wage/${id}`})
    const {data: proj, isLoading: isLoadingProject} = useFetch({url: `/project`})
    const {data: wrk, isLoading: isLoadingWorker} = useFetch({url: `/worker`})

    const isLoading = isLoadingWorker || isLoadingProject || isLoadingWage

    // const projects = (proj?.results || [])
    const projects = wage?.lines?.[0]?.project.map(i => pick(i, ['id', 'name'])) || []
    const worker = wrk?.results || []

    const newData = worker?.map(i => {

        const workerLines = wage?.lines?.filter(j => j.worker.id === i.id)
        const totalDays = sumBy(workerLines, 'days')
        const cost = workerLines?.[0]?.cost || 0

        return {
            ...i,
            cost,
            totalDays,
            total: totalDays * cost
        }
    })

    const useRemove = useMutation({url: `/wage/${id}`, method: "DELETE"})
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const onDelete = async () => {
        useRemove.mutate({id}, {
            onSuccess: (newData) => {
                queryClient.removeQueries(`/wage/${id}`, {exact: true})

                queryClient.setQueryData('/wage', (oldData = []) => {
                    return {...oldData, results: oldData.results.filter(i => i.id !== newData.id)}
                })

                navigate('/wage');
            },
        })
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            id: 'name',
            header: 'العامل',
            // cell: info => <Link to={`/wage/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            accessorKey: 'cost',
            id: 'cost',
            header: 'اليومية',
            cell: info => formatNumber(info.getValue(), null, 0),

        },
        ...projects?.map(i => ({
            accessorKey: i.id,
            id: i.id,
            header: i.name,
            accessorFn: row => wage?.lines?.find(j => j?.worker?.id === row.id)?.project?.find(j => j.id === i.id)?.days || 0,
            // cell: info => formatNumber(info.getValue()),
        })),
        {
            accessorKey: 'totalDays',
            id: 'totalDays',
            header: 'عدد الآيام',
            // cell: info => formatNumber(info.getValue()),

        },
        {
            accessorKey: 'total',
            id: 'total',
            header: 'المجموع',
            cell: info => formatNumber(info.getValue(), null, 0),

        },
    ], [projects, isLoadingProject, proj]);
    console.log(111, projects, columns)


    //  navigate(`/wage/${newData.id}`, {replace: true});
    return <Shell currentPage="wage" loading={isLoading}>
        <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
                <span>الآسبوعيات من {moment(wage?.date?.start).format("DD-MM")} الي {moment(wage?.date?.end).format("DD-MM")}</span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">
                كشف اسبوعيات العمال.
            </p>
        </div>
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={<span><DollarOutlined/>الخلاصة</span>} key="1">
                <OwlTable
                    data={newData || []}
                    columns={columns}
                    buttons={[isAdmin && <Popconfirm
                        title="هل انت متآكد ؟"
                        onConfirm={onDelete}
                        okText="نعم"
                        cancelText="كلا"
                    >
                        <Button type="primary" danger>
                            حذف
                        </Button>
                    </Popconfirm>,
                        isAdmin &&
                        <Button type="primary" onClick={() => navigate(`/wage/${id}/edit`, {replace: true})}>
                            تعديل
                        </Button>
                    ]}
                />
            </Tabs.TabPane>
            <Tabs.TabPane key="2" tab={<span><FilePdfOutlined/>الملفات</span>}
            >
                <Upload owner={id}/>
            </Tabs.TabPane>
        </Tabs>
    </Shell>
}
