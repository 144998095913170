import {Button, Form, Modal, Popconfirm, Tabs} from "antd";
import {useState} from "react";
import {useFetch, useMutation} from "../../api";
import {useQueryClient} from "react-query";
import {isEmpty} from "lodash";
import {PageSpinner} from "../../components/page-spinner";
import {LoanForm} from "../../components/forms/loan";
import moment from "moment";
import {ModalFormFooter} from "../../components/modal-form-footer";
import {DollarOutlined, FilePdfOutlined} from "@ant-design/icons";
import {Upload} from "../../components/upload";


export function EditLoan({id}) {

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {data, isLoading} = useFetch({url: `/loan/${id}`, dontFetch: isEmpty(id) || !isModalVisible})

    const queryClient = useQueryClient()


    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const usePatch = useMutation({url: `/loan/${id}`, method: "PATCH"})
    const useRemove = useMutation({url: `/loan/${id}`, method: "DELETE"})

    const onDelete = async () => {
        useRemove.mutate({id}, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.removeQueries(`/loan/${id}`, {exact: true})

                queryClient.setQueryData('/loan', (oldData = []) => {
                    return {...oldData, results: oldData.results.filter(i => i.id !== newData.id)}
                })
            },
        })
        setIsModalVisible(false)
    };


    const onOk = async () => {
        const values = await form.validateFields()

        if (data.code === values.code) delete values.code
        usePatch.mutate(values, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.setQueryData(`/loan/${id}`, () => newData)

                queryClient.setQueryData('/loan', (oldData = []) =>
                    ({...oldData, results: oldData.results.map(i => i.id === id ? newData : i)}))
            },
        })
    };

    return (<div>
        <Button type="primary" onClick={showModal}>
            تعديل
        </Button>
        <Modal
            title="تعديل دين"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            onCancel={onCancel}
            footer={<ModalFormFooter {...{onOk, onCancel, onDelete}}/>}>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab={<span><DollarOutlined/>البيانات</span>} key="1">

                    <PageSpinner loading={isLoading}>
                        <Form
                            name="basic"
                            form={form}
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            initialValues={{
                                ...data,
                                date: moment(data?.date)
                            }}
                            autoComplete="off"
                        >
                            <LoanForm form={form}/>
                        </Form>
                    </PageSpinner>
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab={<span><FilePdfOutlined/>الملفات</span>}
                >
                    <Upload owner={id}/>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    </div>);
}
