import {useState, useEffect} from "react";
import {
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import generateExcel from "zipcelx";

import {Table} from "./table";
import {fuzzyFilter} from "./fuzzyFilter";
import {IndeterminateCheckbox} from "./indeterminate-checkbox";
import {Button} from "antd";
import {GlobalSearch} from "./global-search";

export function OwlTable({
                             data,
                             columns,
                             isCheckBox,
                             title,
                             subtitle,
                             buttons,
                             isLoading,
                             filename = 'export-file'
                         }) {

    // const [columns] = useState(() => [...defaultColumns.filter(Boolean)])

    // const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
    const [columnVisibility, setColumnVisibility] = useState({})

    const [sorting, setSorting] = useState([])

    const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 10})

    const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilters, setColumnFilters] = useState([])

    const [isFilter, setIsFilter] = useState(false)

    const checkBox = {
        id: 'select',
        header: ({table}) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
        ),
        footer: () => null,
        cell: ({row}) => (
            <div className="px-1">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            </div>
        ),
    }


    const table = useReactTable({
        data,
        columns: isCheckBox ? [checkBox, ...columns.filter(Boolean)] : [...columns.filter(Boolean)],
        state: {
            columnVisibility,
            sorting,
            pagination,
            globalFilter,
            columnFilters,
        },
        pageSize: 20,
        // autoResetPageIndex,
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),

        columnResizeMode: 'onChange',
    })

    useEffect(() => {
        table.setPageSize(20)
    }, []);


    function getHeader(column) {
        return [{
            value: column.column.columnDef.header,
            id: column.column.columnDef.id,
            type: "string"
        }]
    }

    function getExcel(filename) {
        const config = {
            filename,
            sheet: {
                data: []
            }
        };

        const dataSet = config.sheet.data;

        // review with one level nested config
        // HEADERS
        let headers = []
        table.getHeaderGroups().forEach(headerGroup => {
            const headerRow = [];
            if (headerGroup.headers) {
                headerGroup.headers.forEach(column => {
                    headerRow.push(...getHeader(column));
                });
            }

            headers = headerRow
            dataSet.push(headerRow);
        });

        // FILTERED ROWS
        if (table.getCoreRowModel().rows.length > 0) {
            table.getCoreRowModel().rows.forEach(row => {
                const dataRow = [];
                // console.log(row.original, headers)
                headers.forEach(headerID =>
                    dataRow.push({
                        value: row.getValue(headerID.id),
                        type: typeof row.getValue(headerID.id) === "number" ? "number" : "string"
                    })
                );

                dataSet.push(dataRow);
            });
        } else {
            dataSet.push([
                {
                    value: "No data",
                    type: "string"
                }
            ]);
        }

        // console.log(table.getRowModel().rows.length, )
        return generateExcel(config);
    }

    return (
        <div>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {subtitle}
                    </p>
                </div>
            </div>
            <div className="sm:flex sm:items-center">
                {/*    <div className="sm:flex-auto my-5">*/}
                {/*        <GlobalSearch {...{globalFilter, setGlobalFilter}}/>*/}
                {/*    </div>*/}
                {/*    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-1">*/}
                {/*        <label>*/}
                {/*            <input*/}
                {/*                {...{*/}
                {/*                    type: 'checkbox',*/}
                {/*                    checked: isFilter,*/}
                {/*                    onChange: () => setIsFilter(e => !e),*/}
                {/*                }}*/}
                {/*            />*/}
                {/*            Filter*/}
                {/*        </label>*/}

                {/*<button*/}
                {/*            type="button"*/}
                {/*            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"*/}
                {/*        >*/}
                {/*            Refresh*/}
                {/*        </button>*/}
                {/*        <button*/}
                {/*            type="button"*/}
                {/*            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"*/}
                {/*        >*/}
                {/*            Options*/}
                {/*        </button>*/}
                {/*    </div>*/}
            </div>
            {/*<ColumnVisibility table={table}/>*/}
            <div className="flex justify-between items-center">
                <div className="flex space-x-2 space-x-reverse">
                    {buttons}
                    <Button onClick={() => getExcel(filename)}>
                        تصدير
                    </Button>
                </div>
                <div className="">
                    <GlobalSearch {...{globalFilter, setGlobalFilter}}/>
                </div>
            </div>
            <Table table={table} isFilter={isFilter} isLoading={isLoading}/>
        </div>
    );
}
